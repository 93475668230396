import React from "react"
import "./traject.scss"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO
      title="Maak kennis met het team"
      description="Initiator van de Zipper® Methode, Ellen Schoone, zij heeft in de afgelopen jaren topsporters gecoacht om sportieve prestaties te verbeteren, herstel te bevorderen"
    />
    <div className={"parralaxContact topPrlx"}></div>

    <h1 style={{ textAlign: "center", padding: "25px 0" }}>
      Maak kennis met het team van Zipperr®
    </h1>
    <div className="containerPad grid-system contactGrid">
      <div className={"leftContactSection"}>
        <p>
          We leven in een maatschappij, waarin iedereen zich coach mag noemen.
        </p>
        <p>
          Initiator van de Zipper® Methode, Ellen Schoone, is een coach die
          aangesloten is bij de{" "}
          <a href="https://www.nobco.nl/">
            NOBCO, de Nederlandse Orde van Beroepscoaches.
          </a>{" "}
          Bovendien is zij geaccrediteerd ReAttach therapeute en trainer,
          waarbij zij andere therapeuten opleidt. Zij heeft in de afgelopen
          jaren topsporters gecoacht om sportieve prestaties te verbeteren,
          herstel te bevorderen en te kunnen excelleren. Deze methode is ook
          aanbevolen voor iedereen die geïnteresseerd is in persoonlijke groei
          of organisatieontwikkeling.
        </p>

        <p>
          Zipperr® werkt met gecertificeerde coaches, aangesloten bij de NOBCO.
          Onder deze vlag worden onze professionele coaches landelijk ingezet.
          Kwaliteiten, vaardigheden, niveau en (levens)ervaring zijn belangrijke
          criteria om in het ZIPPERR® team te werken.
        </p>
        <p>
          Heb je belangstelling om deel uit te maken van ons team, neem dan
          contact met ons op!
        </p>

        <Link to={"/traject"}>
          Klik hier voor de Trajecten van een Zipperr® traject
        </Link>
        <p>
          <strong>Is jouw belangstelling gewekt?</strong>
        </p>
        <p>Neem dan vandaag nog telefonisch contact op met Zipperr</p>
        <div className="contactButtons">
          <div className="btn-container">
            <a
              href="tel:0653667567"
              className={"btn primary-lg round expand-on-hover"}
            >
              Bel nu!
            </a>
          </div>
          <div className="btn-container">
            <a
              href="https://www.google.com/maps?q=Kerkstraat+7A+5671+GN+Nuenen&rlz=1C1GCEA_enNL834NL834&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi1yp_I0fvmAhUkMewKHTipBDcQ_AUoAXoECAwQAw"
              className={"btn primary-lg round expand-on-hover"}
            >
              Navigeer!
            </a>
          </div>
        </div>
      </div>

      <div className="rightSideContactBar">
        <h3>contact gegevens</h3>
        <div className="iconsSide">
          <p>
            <a href="tel:0653667567">Tel: 06 536 675 67</a>
          </p>
          <p>
            <a href="mailto:info@zipper.nl?SUBJECT=website-contact">
              E-Mail: info@zipperr.nl
            </a>
          </p>
          <p>
            <a href="https://www.google.com/maps?q=Kerkstraat+7A+5671+GN+Nuenen&rlz=1C1GCEA_enNL834NL834&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi1yp_I0fvmAhUkMewKHTipBDcQ_AUoAXoECAwQAw">
              Adres: Kerkstraat 7A <br />
              5671 GN Nuenen.
            </a>
          </p>
        </div>
      </div>
    </div>
    <div className={"parralaxContact"} />
  </Layout>
)

export default Contact
